import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import LogoSvg from '@did/das-assets/images/icon/logo-black.svg'
import { IBaseProps } from '@did/types'
import {
  TabAliasIcon,
  TabExplorerIcon,
  TabMeIcon,
  TopNav,
  TopNavMobile
} from '@did/uikit'
import React, { useEffect, useState, useMemo } from 'react'
import { LayoutRight } from './layout-right'
import { cn, toChecksumAddress } from '@did/tools'
import { Context } from './context'
import { BackButton } from '@did/das-app-components'
import { DobTab } from './dob-tab'
import { ChainId, ChainIdToCoinTypeMap, CoinType } from '@did/constants/chain'
import { DobSwitcher } from './dob-switcher'

const BACKGROUND_OF_THE_PATH = [
  '/create/account/[name]',
  '/account/create/auction/[bitname]',
  '/account/create/[bitname]',
  '/account/create/status/[bitname]',
  '/account/create/status/auction/[bitname]',
  '/alias'
]

const MENUS_SHOW_PATHS = ['/create', '/my', '/debug', '/unlock']

export const BaseLayout: React.FC<IBaseProps> = ({ children }) => {
  const { tt, router, isProd } = useDasBalanceContext()
  const {
    ccc,
    setAddress,
    setChainId,
    setCkbAddress,
    setCoinType,
    setIsTestNet
  } = useCccContext()
  const cccConnector = ccc?.useCcc()
  const signer = ccc?.useSigner()
  const [isMobile, setIsMobile] = useState(false)
  const [refreshReverseInfo, setRefreshReverseInfo] = useState<
    () => Promise<void>
  >(() => Promise.resolve())
  const [scrollY, setScrollY] = useState(0)
  const [navbarBlur, setNavbarBlur] = useState(0)

  const backButton = useMemo(() => {
    return (
      <BackButton
        onClick={() => {
          if (
            [
              '/account/create/status/[bitname]',
              '/account/create/status/auction/[bitname]'
            ].includes(router?.pathname)
          ) {
            router.push('/create')
          } else if (
            window.history.length <= 2 &&
            ['/data/[bitname]'].includes(router?.pathname)
          ) {
            router.push('/create')
          } else if (['/data/[bitname]'].includes(router?.pathname)) {
            router.push('/my')
          } else {
            router.back?.()
          }
        }}
      />
    )
  }, [router?.pathname])

  const toggleModeShow = useMemo(() => {
    return [
      '/my',
      '/my/upgradeable-list',
      '/referral',
      '/create',
      '/debug'
    ].includes(router?.pathname)
  }, [router?.pathname])

  const backgroundOfThePath = useMemo(() => {
    const appBg = `bg-[url('/bit/images/layouts/app-bg.svg')]`

    return BACKGROUND_OF_THE_PATH.includes(router?.pathname)
      ? isMobile
        ? 'bg-layout-mobile'
        : appBg
      : 'bg-slate-50'
  }, [router?.pathname, isMobile])

  const menusShowPaths = useMemo(() => {
    return MENUS_SHOW_PATHS.includes(router.pathname)
  }, [router?.pathname])

  const logoShowPaths = useMemo(() => {
    return ['/alias'].includes(router.pathname)
  }, [router?.pathname])

  const logo = (
    <LogoSvg
      className="h-8 max-md:h-7 cursor-pointer"
      onClick={() => {
        router.push('/create')
      }}
    />
  )

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    const handleScroll = () => {
      setScrollY(window.scrollY)
    }
    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const maxScroll = isMobile ? 62 : 86
    const opacity = Math.min(scrollY / maxScroll, 0.9)
    setNavbarBlur(parseInt(String(opacity * 10 + 1)))
  }, [scrollY])

  useEffect(() => {
    if (!signer) {
      setAddress('')
      setChainId('')
      setCkbAddress('')
      setCoinType('')
      return
    }

    ;(async () => {
      if (isProd && signer?.client?.addressPrefix === 'ckt') {
        return
      }

      setCkbAddress(await signer?.getRecommendedAddress())

      const signerType = signer?.type
      let chainId: string | number = ''
      let _coinType: string = ''

      if (signerType === 'EVM') {
        setAddress(toChecksumAddress(await signer?.getInternalAddress()))
        chainId =
          signer?.client?.addressPrefix === 'ckt'
            ? ChainId.ethHolesky
            : ChainId.eth
        _coinType = ChainIdToCoinTypeMap[chainId]
      } else if (signerType === 'BTC') {
        setAddress(await signer?.getInternalAddress())
        chainId =
          signer?.client?.addressPrefix === 'ckt' ? 'testnet' : 'livenet'
        _coinType = ChainIdToCoinTypeMap[chainId]
      } else if (signerType === 'CKB') {
        setAddress(await signer?.getInternalAddress())
        chainId = ''
        _coinType = CoinType.ckb
      } else if (signerType === 'Nostr') {
        setAddress(await signer?.getInternalAddress())
        chainId = ''
        _coinType = CoinType.nostr
      }

      setChainId(chainId)
      setCoinType(_coinType)
      setIsTestNet(!isProd)
    })()
  }, [signer, isProd])

  useEffect(() => {
    const client = isProd
      ? ccc?.ClientPublicMainnet && new ccc.ClientPublicMainnet()
      : ccc?.ClientPublicTestnet && new ccc.ClientPublicTestnet()
    if (client) {
      cccConnector?.setClient?.(client)
    }
  }, [isProd, cccConnector?.setClient, ccc])

  return (
    <Context.Provider value={{ refreshReverseInfo, setRefreshReverseInfo }}>
      {BACKGROUND_OF_THE_PATH.includes(router?.pathname) && (
        <meta name="theme-color" content="#DAEFF3" />
      )}
      <div
        className={cn(
          'flex flex-col min-h-screen bg-no-repeat bg-center bg-cover',
          backgroundOfThePath
        )}
      >
        <TopNavMobile
          className={cn(
            'lg:hidden backdrop-blur-[30px]',
            navbarBlur === 10 && 'border-b border-[#7d7d7d1a]'
          )}
          currPath={router.pathname}
          handleMenuClick={(p?: string) => {
            router.push(p!)
          }}
          Logo={
            menusShowPaths || logoShowPaths ? (
              toggleModeShow ? (
                <DobSwitcher />
              ) : (
                logo
              )
            ) : (
              backButton
            )
          }
          Right={LayoutRight}
          menus={
            menusShowPaths
              ? [
                  {
                    text: tt('Create'),
                    path: '/create',
                    iconComponent: TabExplorerIcon
                  },
                  {
                    text: tt('My DIDs'),
                    path: '/my',
                    iconComponent: TabMeIcon
                  },
                  {
                    text: tt('Alias'),
                    href: '/bit/alias',
                    iconComponent: TabAliasIcon
                  }
                ]
              : undefined
          }
        />
        <TopNav
          className={cn(
            'hidden lg:block backdrop-blur-[30px]',
            navbarBlur === 10 && 'border-b border-[#7d7d7d1a]'
          )}
          currPath={router.pathname}
          handleMenuClick={(p?: string) => {
            router.push(p!)
          }}
          Logo={
            menusShowPaths || logoShowPaths ? (
              <span className="inline-flex items-center md:w-[30vw] gap-x-8">
                {logo}
                {toggleModeShow && <DobTab />}
              </span>
            ) : (
              backButton
            )
          }
          Right={LayoutRight}
          menus={
            menusShowPaths
              ? [
                  {
                    text: tt('Create'),
                    path: '/create'
                  },
                  {
                    text: tt('My DIDs'),
                    path: '/my'
                  },
                  {
                    text: tt('Alias'),
                    href: '/bit/alias'
                  }
                ]
              : undefined
          }
        />
        {children}
      </div>
    </Context.Provider>
  )
}

import { useMemo } from 'react'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { cn } from '@did/tools'

export const DobTab = () => {
  const { tt, router, connectedAccount, walletSdk } = useDasBalanceContext()
  const { isDobsMode, setIsDobsMode } = useCccContext()

  const items = useMemo(() => {
    return [
      {
        text: tt('Legacy .bit'),
        value: false
      },
      {
        text: tt('DOBs Compatible .bit'),
        value: true
      }
    ]
  }, [tt])

  const onButtonChange = async (value: boolean) => {
    if (
      !['/referral', '/create', '/my'].includes(router.pathname) &&
      !connectedAccount?.address &&
      value === true
    ) {
      walletSdk?.connectWallet?.()
      return
    }
    if (router.pathname === '/create' && value === true) {
      await router.push('/my')
    }
    setIsDobsMode(value)
  }

  return (
    <div className="inline-flex rounded-full p-1 gap-x-1 bg-neutral-300/20">
      {items.map((item, index) => (
        <button
          key={index}
          className={cn(
            'rounded-full text-sm font-medium outline-0 cursor-pointer px-3 py-1',
            isDobsMode === item.value
              ? isDobsMode === true
                ? 'text-zinc-800 bg-gradient-to-r from-sky-100 to-indigo-50 border border-blue-100 shadow-tab-button'
                : 'text-zinc-800 bg-white shadow-tab-button'
              : 'text-neutral-400'
          )}
          onClick={() => onButtonChange(item.value)}
        >
          {item.text}
        </button>
      ))}
    </div>
  )
}

import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { useEffect, useMemo, useState } from 'react'
import { Iconfont, Dropdown } from '@did/uikit'
import { cn } from '@did/tools'

export const DobSwitcher = () => {
  const { tt, router, connectedAccount, walletSdk } = useDasBalanceContext()
  const { isDobsMode, setIsDobsMode } = useCccContext()
  const [title, setTitle] = useState('')

  const options = useMemo(() => {
    return [
      {
        title: tt('Legacy .bit'),
        label: (
          <div
            className={cn(
              'inline-flex flex-row max-w-full w-full items-center justify-between gap-2.5 px-3 py-3.5 rounded hover:bg-slate-600/10 active:bg-slate-600/20'
            )}
          >
            <span>
              <div className="text-neutral-900 text-base font-semibold">
                {tt('Legacy .bit')}
              </div>
              <div className="mt-1 text-zinc-500 text-sm font-normal">
                {tt('Most common .bit with lower storage fee.')}
              </div>
            </span>
            {isDobsMode === false && (
              <Iconfont name="check-strong" color="#1ED760" />
            )}
          </div>
        ),
        value: false
      },
      {
        title: tt('DOBs Compatible .bit'),
        label: (
          <div
            className={cn(
              'inline-flex flex-row max-w-full w-full items-center justify-between gap-2.5 px-3 py-3.5 rounded hover:bg-slate-600/10 active:bg-slate-600/20'
            )}
          >
            <span>
              <div className="text-neutral-900 text-base font-semibold">
                {tt('DOBs Compatible .bit')}
              </div>
              <div className="mt-1 max-w-[71vw] text-zinc-500 text-sm font-normal">
                {tt(
                  'Greater interoperability with other apps in the CKB ecosystem, with higher storage fee.'
                )}
              </div>
            </span>
            {isDobsMode === true && (
              <Iconfont name="check-strong" color="#1ED760" />
            )}
          </div>
        ),
        value: true
      }
    ]
  }, [tt, isDobsMode])

  const onButtonChange = async (value: boolean) => {
    // If the current page is not referral, create, or my, and the user is not connected, connect the wallet first.
    if (
      !['/referral', '/create', '/my'].includes(router.pathname) &&
      !connectedAccount?.address &&
      value === true
    ) {
      walletSdk?.connectWallet?.()
      return
    }
    let title = ''
    options.forEach((option) => {
      if (option.value === value) {
        title = option.title
      }
    })
    setTitle(title)
    if (router.pathname === '/create' && value === true) {
      await router.push('/my')
    }
    setIsDobsMode(value)
  }

  useEffect(() => {
    let title = ''
    options.forEach((option) => {
      if (option.value === isDobsMode) {
        title = option.title
      }
    })
    setTitle(title)
  }, [options, isDobsMode])

  return (
    <Dropdown
      align="start"
      options={options}
      itemClassName="flex items-center cursor-pointer outline-0 border-0"
      onMenuClick={onButtonChange}
    >
      <div
        className={cn(
          'inline-flex items-center cursor-pointer px-3 h-[38px] rounded-full border text-left break-words text-neutral-700 font-semibold text-xs',
          isDobsMode
            ? 'bg-gradient-to-r from-sky-100 to-indigo-50 border-blue-100'
            : 'bg-white border-stone-300/20'
        )}
      >
        {title}
        <Iconfont className="ml-1" size="12" name="toggle" color="#5F6570" />
      </div>
    </Dropdown>
  )
}

import { Decimal } from '@did/tools'
import {
  useCccContext,
  useDasAppConfigContext,
  useDasBalanceContext
} from '@did/das-app-context'
import React, { useMemo, useState } from 'react'
import { DasButton, Dialog, Iconfont } from '@did/uikit'
import {
  DIDPOINT_ACTIONS,
  FIAT_DECIMAL_PLACES,
  TOKEN_DECIMAL_PLACES
} from '@did/constants'
import { IEditOwnerRes, IToken } from '@did/types'
import {
  CCCBalanceTokenId,
  CoinType,
  CoinTypeToChainMap,
  DASBalanceTokenId,
  DASDidPointTokenId,
  USD
} from '@did/constants/chain'
import { PaymentConfirm } from '../../account/register-page/payment-confirm'
import { FiatPayDialog } from '@did/stripe'
import { DasBalanceInsufficientDialog } from '../../account/register-page/das-balance-insufficient-dialog'
import { SignatureErrorDialog } from '../../account/register-page/signature-error-dialog'
import { useTokenList } from '@did/das-app-hooks'
import errno from '@did/constants/errno'
import { handleError } from '@did/das-app-utils'
import { DobRenewalDialogType } from '@did/das-app-types/module'

export const DobRenewalDialog: DobRenewalDialogType = ({
  showing,
  renewalPeriod,
  accountInfo,
  setTxHash,
  setTxHashLink,
  setRenewalSubmittedShowing,
  onClose: onCloseDialog,
  onConnectLegacyWallet,
}) => {
  const { tt, services, connectedAccount, walletSdk, isProd, alert } =
    useDasBalanceContext()
  const { ccc, ckbAddress } = useCccContext()
  const signer = ccc?.useSigner()
  const cccConnector = ccc?.useCcc()
  const config = useDasAppConfigContext()
  const [secondStepShowing, setSecondStepShowing] = useState(false)
  const [selectedToken, setSelectedToken] = useState<IToken>()
  const paymentTokens = useTokenList()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [
    dasBalanceInsufficientDialogShowing,
    setDasBalanceInsufficientDialogShowing
  ] = useState(false)
  const [signatureErrorDialogShowing, setSignatureErrorDialogShowing] =
    useState(false)
  const [clientSecret, setClientSecret] = useState('')
  const [fiatPayDialogShowing, setFiatPayDialogShowing] = useState(false)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [prepared, setPrepared] = useState(false)
  const [orderInfo, setOrderInfo] = useState<IEditOwnerRes>({} as IEditOwnerRes)

  const renewalAmount = useMemo(() => {
    const _fee = new Decimal(renewalPeriod || 0).times(
      accountInfo?.account_price || 0
    )
    return _fee.toFixed(FIAT_DECIMAL_PLACES)
  }, [renewalPeriod, accountInfo?.account_price])

  const stripeServiceFee = useMemo(() => {
    if (!selectedToken?.decimals) return ''
    return new Decimal(renewalAmount || 0)
      .times(config?.premium_percentage || 0)
      .add(config?.premium_base || 0)
      .toFixed(selectedToken?.decimals, Decimal.ROUND_UP)
  }, [selectedToken, renewalAmount, config])

  const stripePaidAmount = useMemo(() => {
    if (!selectedToken?.decimals) return ''
    return new Decimal(renewalAmount || 0)
      .add(stripeServiceFee)
      .toFixed(selectedToken.decimals, Decimal.ROUND_UP)
  }, [renewalAmount, stripeServiceFee, selectedToken?.decimals])

  const paidTokenAmount = useMemo(() => {
    let tokenPrecision = TOKEN_DECIMAL_PLACES

    if (selectedToken?.token_id === USD.tokenId) {
      tokenPrecision = selectedToken?.decimals
    }

    if (selectedToken?.token_id === DASDidPointTokenId) {
      tokenPrecision = 2
    }

    return new Decimal(renewalAmount || 0)
      .div(selectedToken?.price || 1)
      .times(selectedToken?.premium || 1)
      .toFixed(tokenPrecision, Decimal.ROUND_UP)
  }, [renewalAmount, selectedToken])

  const amountVerify = (amount: number | string): string => {
    let number = amount
    // Compatible with TokenPocket
    if (selectedToken?.coin_type === CoinType.doge) {
      number = new Decimal(number || 0)
        .div(new Decimal(10).pow(TOKEN_DECIMAL_PLACES))
        .toFixed(TOKEN_DECIMAL_PLACES)
    }
    return number + ''
  }

  const returnUrl = useMemo(() => {
    try {
      return window?.location?.href
    } catch (e: any) {
      return ''
    }
  }, [])

  const onSelectPayment = async () => {
    if (!ckbAddress) {
      cccConnector?.open()
      return
    }

    setSecondStepShowing(true)
  }

  const handleConfirm = async () => {
    if ( !selectedToken ) {
      return
    }

    setConfirmLoading(true)

    try {
      let _orderInfo
      if (ckbAddress) {
        _orderInfo = await services.account.submitRenewOrder({
          key_info: {
            coin_type: selectedToken?.coin_type,
            key: ckbAddress
          },
          account: accountInfo.account,
          pay_token_id: selectedToken?.token_id!,
          renew_years: Number(renewalPeriod)
        })
      } else {
        if (!connectedAccount || !connectedAccount.address) {
          setConfirmLoading(false)
          return
        }

        _orderInfo = await services.account.submitRenewOrder({
          key_info: {
            coin_type: connectedAccount.chain?.coinType,
            key: connectedAccount.address
          },
          account: accountInfo.account,
          pay_token_id: selectedToken?.token_id!,
          renew_years: Number(renewalPeriod)
        })
      }

      if (!_orderInfo) {
        setConfirmLoading(false)
        return
      }

      if (_orderInfo.ckb_tx) {
        if (!signer?.signTransaction) {
          setConfirmLoading(false)
          return
        }
        // @ts-ignore
        const { cccA } = await import('@ckb-ccc/connector-react/advanced')
        const ckbTx = cccA.JsonRpcTransformers.transactionTo(
          JSON.parse(_orderInfo.ckb_tx)
        )
        let ckbSignature = await signer?.signTransaction(ckbTx)
        ckbSignature = cccA.JsonRpcTransformers.transactionFrom(ckbSignature)
        await services.account.sendTrx({
          ..._orderInfo,
          ckb_tx: JSON.stringify(ckbSignature)
        })
      }

      setOrderInfo(_orderInfo)
      setPrepared(true)
      setSecondStepShowing(false)
    } catch (err: any) {
      if (err.code === errno.apiErrorCodeInsufficientBalance) {
        setDasBalanceInsufficientDialogShowing(true)
      } else if (err.code === errno.apiErrorCodeNotEnoughChange) {
        alert({
          title: tt('Tips'),
          message: tt(
            'DAS is a smart contract that runs on the Nervos. Due to the underlying logic of the contract, the remaining amount is too low (less than {minChangeCapacity} CKB) to send a transaction.',
            {
              minChangeCapacity: config.min_change_capacity
            }
          )
        })
      } else if (err.code === errno.rpcApiErrSignatureError) {
        setSignatureErrorDialogShowing(true)
      } else if (err.code === errno.apiErrorAccountRegistering) {
        alert({
          title: tt('Tips'),
          message: tt(
            'Someone else is registering {accountName}, it is currently unavailable, please try again later',
            { accountName: accountInfo.account }
          )
        })
      } else {
        handleError(err, tt, alert)
      }
    } finally {
      setConfirmLoading(false)
    }
  }

  const handleConnectLegacyWallet = () => {
    setSecondStepShowing(false)
    onConnectLegacyWallet?.()
  }

  const onExtend = async () => {
    setPaymentLoading(true)
    let signTxList: any
    let onClose: any

    if (
      ![USD.tokenId, CCCBalanceTokenId].includes(
        selectedToken?.token_id as string
      )
    ) {
      const signContext = await walletSdk?.initSignContext()
      signTxList = signContext.signTxList
      onClose = signContext.onClose
    }

    try {
      if (selectedToken?.token_id === USD.tokenId) {
        setClientSecret(orderInfo.client_secret!)
        setOrderId(orderInfo.order_id!)
        setSecondStepShowing(false)
        setFiatPayDialogShowing(true)
      } else if (selectedToken?.token_id === DASBalanceTokenId) {
        const txs = await services.account.dasBalancePay({
          key_info: {
            coin_type: connectedAccount?.chain?.coinType!,
            key: connectedAccount?.address!
          },
          evm_chain_id: connectedAccount?.computedChainId!,
          order_id: orderInfo?.order_id!
        })

        const signatureList = await signTxList(txs)

        const { hash } = await services.account.sendTrx(signatureList)
        if (hash) {
          setSecondStepShowing(false)
          setRenewalSubmittedShowing(true)
          if (CoinTypeToChainMap[selectedToken?.coin_type!]) {
            setTxHash(hash)
            setTxHashLink(
              `${CoinTypeToChainMap[selectedToken?.coin_type].getExplorerTrx(
                isProd
              )}${hash}`
            )
          }
        }
      } else if (selectedToken?.token_id === CCCBalanceTokenId) {
        if (!signer) {
          return
        }

        const { script: toLock } = await ccc.Address.fromString(
          orderInfo.receipt_address,
          signer.client
        )

        const orderId = ccc.bytesFrom(orderInfo.order_id, 'utf8')

        const dataBytes = (() => {
          try {
            return ccc.bytesFrom(orderId)
          } catch (e) {}

          return ccc.bytesFrom(orderId, 'utf8')
        })()

        const tx = ccc.Transaction.from({
          outputs: [{ lock: toLock }],
          outputsData: [dataBytes]
        })

        // CCC transactions are easy to be edited
        tx.outputs.forEach((output: any, i: number) => {
          if (output.capacity > ccc.fixedPointFrom(orderInfo.amount, 0)) {
            throw new Error(
              `Insufficient capacity at output ${i} to store data`
            )
          }
          output.capacity = ccc.fixedPointFrom(orderInfo.amount, 0)
        })

        await tx.completeInputsByCapacity(signer)
        await tx.completeFeeBy(signer, 1000)

        const txHash = await signer.sendTransaction(tx)
        if (txHash) {
          setSecondStepShowing(false)
          setRenewalSubmittedShowing(true)
          if (CoinTypeToChainMap[selectedToken?.coin_type!]) {
            setTxHash(txHash)
            setTxHashLink(
              `${CoinTypeToChainMap[selectedToken?.coin_type].getExplorerTrx(
                isProd
              )}${txHash}`
            )
          }
        }
      } else if (selectedToken?.token_id === DASDidPointTokenId) {
        const res = await services.didPoint.transfer({
          key_info: {
            coin_type: connectedAccount?.chain?.coinType!,
            key: connectedAccount?.address!
          },
          amount_dp: new Decimal(orderInfo?.amount || 0)
            .dividedBy(new Decimal(10).pow(selectedToken?.decimals || 6))
            .toString(),
          order_id: orderInfo?.order_id!,
          action_dp: DIDPOINT_ACTIONS.transfer_tldid
        })

        const signatureList = await signTxList(res)

        const { hash } = await services.didPoint.txSend(signatureList)
        if (hash) {
          setSecondStepShowing(false)
          setRenewalSubmittedShowing(true)
          if (CoinTypeToChainMap[selectedToken?.coin_type!]) {
            setTxHash(hash)
            setTxHashLink(
              `${CoinTypeToChainMap[selectedToken?.coin_type].getExplorerTrx(
                isProd
              )}${hash}`
            )
          }
        }
      } else {
        const trxAmount = amountVerify(orderInfo?.amount || '0')
        const hash = await walletSdk.sendTransaction({
          to: orderInfo.receipt_address,
          value: trxAmount,
          data: orderInfo.order_id
        })

        if (hash) {
          setSecondStepShowing(false)
          setRenewalSubmittedShowing(true)
          if (CoinTypeToChainMap[selectedToken?.coin_type!]) {
            setTxHash(hash)
            setTxHashLink(
              `${CoinTypeToChainMap[selectedToken?.coin_type!].getExplorerTrx(
                isProd
              )}${hash}`
            )
          }
        }
      }

      onCloseDialog()
      setPrepared(false)
    } catch (err: any) {
      onClose?.()

      if (err.code === errno.apiErrorCodeInsufficientBalance) {
        setDasBalanceInsufficientDialogShowing(true)
      } else if (err.code === errno.apiErrorCodeNotEnoughChange) {
        alert({
          title: tt('Tips'),
          message: tt(
            'DAS is a smart contract that runs on the Nervos. Due to the underlying logic of the contract, the remaining amount is too low (less than {minChangeCapacity} CKB) to send a transaction.',
            {
              minChangeCapacity: config.min_change_capacity
            }
          )
        })
      } else if (err.code === errno.rpcApiErrSignatureError) {
        setSignatureErrorDialogShowing(true)
      } else if (err.code === errno.apiErrorAccountRegistering) {
        alert({
          title: tt('Tips'),
          message: tt(
            'Someone else is registering {accountName}, it is currently unavailable, please try again later',
            { accountName: accountInfo.account }
          )
        })
      } else {
        handleError(err, tt, alert)
      }
    } finally {
      setPaymentLoading(false)
    }
  }

  return (
    <>
      <Dialog
        showing={showing}
        title={tt('Extend')}
        closeButton
        onClose={() => {
          setPrepared(false)
          onCloseDialog()
        }}
      >
        <div className="flex flex-col gap-y-3 items-center mb-3 rounded-xl border border-neutral-200 p-4">
          <span className="text-sm font-semibold text-soft-400 rounded-full bg-neutral-100 px-3 py-0.5">
            {tt('Step1')}
          </span>
          <DasButton
            black
            block
            onClick={onSelectPayment}
            isLoadingGradient={false}
            disabled={prepared}
          >
            {prepared ? (
              <span className="inline-flex gap-x-1 items-center">
                <Iconfont name="check-strong" color="#FFFFFF" size="24" />
                {tt('Done')}
              </span>
            ) : (
              tt('Select payment')
            )}
          </DasButton>
        </div>
        <div className="flex flex-col gap-y-3 items-center mb-8 rounded-xl border border-neutral-200 p-4">
          <span className="text-sm font-semibold text-soft-400 rounded-full bg-neutral-100 px-3 py-0.5">
            {tt('Step2')}
          </span>
          <DasButton
            black
            block
            disabled={!prepared}
            onClick={onExtend}
            isLoadingGradient={false}
            loading={paymentLoading}
          >
            {tt('Confirm payment and extend')}
          </DasButton>
        </div>
      </Dialog>
      <Dialog
        showing={secondStepShowing}
        title={tt('Select payment')}
        closeButton
        onClose={() => {
          setSecondStepShowing(false)
        }}
      >
        <PaymentConfirm
          className="px-0"
          onTokenChange={setSelectedToken}
          onConnectLegacyWallet={handleConnectLegacyWallet}
          selectedToken={selectedToken}
          stripePaidAmount={stripePaidAmount}
          stripeServiceFee={stripeServiceFee}
          paidTokenAmount={paidTokenAmount}
          fiatTermsLink="https://topdid.com/terms.html"
          tokenList={paymentTokens}
          handleConfirm={handleConfirm}
          confirmRegisterLoading={confirmLoading}
        />
      </Dialog>
      <FiatPayDialog
        showing={fiatPayDialogShowing}
        title={tt('Pay to Join')}
        onClose={() => {
          setFiatPayDialogShowing(false)
        }}
        returnUrl={returnUrl}
        clientSecret={clientSecret!}
        orderId={orderId}
        successCallback={() => {
          setTxHash('')
          setTxHashLink('')
          setFiatPayDialogShowing(false)
          setRenewalSubmittedShowing(true)
        }}
      />
      <DasBalanceInsufficientDialog
        selectToken={selectedToken}
        showing={dasBalanceInsufficientDialogShowing}
        onClose={() => {
          setDasBalanceInsufficientDialogShowing(false)
        }}
        registrationFees={paidTokenAmount}
      />
      <SignatureErrorDialog
        showing={signatureErrorDialogShowing}
        onClose={() => {
          setSignatureErrorDialogShowing(false)
        }}
      />
    </>
  )
}

import { LanguageOptions } from '@did/constants/language'
import { Services } from '@did/das-services'
import { buildTt } from '@did/i18n'
import {
  IContext,
  IMonitor,
  IServiceUpgradeNotice
} from '@did/types/das-context'
import { NextRouter } from 'next/router'
import { ILanguageOption, LANGUAGE } from '@did/types/uikit'
import { useEffect, useMemo, useState, useRef } from 'react'
import { CoinTypeToChainMap } from '@did/constants/chain'
import errno from '@did/constants/errno'
import { calcAccount } from '@did/tools/calc-account'
import { DasButton, Iconfont, openDialog, openToast } from '@did/uikit'
import { createConfig, http } from '@wagmi/core'
import {
  bsc,
  bscTestnet,
  holesky,
  mainnet as ethereum,
  polygon,
  polygonAmoy
} from '@wagmi/core/chains'
import { injected, walletConnect } from '@wagmi/connectors'
import { AxiosError, AxiosResponse } from 'axios'
import { ALERT_KEY } from '@did/constants'
import { smartOpen, ErrorInfo } from '@did/tools'
import { ParsedUrlQueryInput } from 'node:querystring'

let upgradeNotified = false

const LOCAL_LANG_KEY = 'DAS_LANG'

// to do: save to localstorage
const DEFAULT_CONFIG = {
  reverse_record_capacity: 20100000000,
  min_change_capacity: 12000000000,
  sale_cell_capacity: 20100000000,
  min_sell_price: 20000000000,
  account_expiration_grace_period: 7776000,
  min_ttl: 300,
  profit_rate_of_inviter: '0.1',
  inviter_discount: '0.05',
  min_account_len: 4,
  max_account_len: 42,
  edit_records_throttle: 300,
  edit_manager_throttle: 300,
  transfer_throttle: 300,
  income_cell_min_transfer_value: 12000000000,
  premium: '0',
  timestamp_on_chain: 1700619149,
  premium_percentage: '0.036',
  premium_base: '0.52'
}

function onFulfilled({
  handleSystemUpgrade
}: {
  handleSystemUpgrade: () => void
}) {
  return (res: AxiosResponse) => {
    if (res.data) {
      if (res.data.err_no === errno.success) {
        return res.data.data
      } else {
        if (res.data.err_no === errno.apiErrorCodeSystemUpgrade) {
          if (!upgradeNotified) {
            handleSystemUpgrade?.()
          }
          upgradeNotified = true
          return DEFAULT_CONFIG
        } else {
          const serverError: any = new Error(res.data.err_msg)
          serverError.code = res.data.err_no
          throw serverError
        }
      }
    } else {
      const serverError: any = new Error(res.statusText)
      serverError.code = res.status
      throw serverError
    }
  }
}

function onRejected() {
  return (err: AxiosError) => {
    const code = err?.response?.status || errno.networkError
    err.code = String(code)
    err.message = `api error: ${err?.response?.statusText || err.message}`
    throw err
  }
}

export interface UseContextValParam {
  router: NextRouter
  localesData?: Array<{ key: LANGUAGE; data: any }>
  monitor?: IMonitor
  config: {
    isProdData: boolean
    baseURL: string
    reverseApiUrl?: string
    didPointApiUrl?: string
    subAccountApiUrl?: string
    walletconnectProjectId: string
    ckbNode?: string
    headers?: any
    upgradeNoticeUrl?: string
  }
  outSiteUrl?: IContext['outSiteUrl']
  customChains?: any[]
  typeId?: {
    dasLock: string
    omniLock: string
    joyidLock: string
    nostrLock: string
  }
}

export const useContextVal = (param: UseContextValParam) => {
  const { config, localesData, monitor, outSiteUrl, customChains, router, typeId } =
    param
  const [wallet, setWallet] = useState<any>(null)
  const [connectedAccount, setConnectedAccount] = useState<any>({
    address: '',
    chain: null,
    protocol: null
  })
  const [locale, setLocaleState] = useState(LANGUAGE.en)
  const [translation, setTranslation] = useState({} as any)
  const walletIsInited = useRef<boolean>(false)
  const alertKeyArr = useRef<string[]>([])

  const upgradeNoticeLoadingRef = useRef<boolean>(true)
  const upgradeNoticeRef = useRef<IServiceUpgradeNotice | null>()
  const localeRef = useRef<LANGUAGE>(LANGUAGE.en)

  const setLocale = (lang: LANGUAGE) => {
    localeRef.current = lang
    setLocaleState(lang)
  }
  const tt = useMemo(() => {
    return buildTt(translation, locale || 'en')
  }, [translation, locale])

  useEffect(() => {
    if (!config?.upgradeNoticeUrl) {
      // upgradeNoticeLoadingRef.current = false;
      return
    }
    upgradeNoticeLoadingRef.current = true

    fetch(`${config.upgradeNoticeUrl}?t=${Math.random()}`)
      .then((d) => d.json())
      .then((d) => {
        if (
          d?.end_date &&
          new Date(`${d.end_date}T24:00:00.000+0800`) < new Date()
        ) {
          upgradeNoticeRef.current = null
          return
        }
        upgradeNoticeRef.current = d
      })
      .catch((e) => {
        ErrorInfo.error(e)
        upgradeNoticeRef.current = null
      })
      .finally(() => {
        upgradeNoticeLoadingRef.current = false
      })
  }, [config?.upgradeNoticeUrl])

  const alert: IContext['alert'] = (param) => {
    if (param?.key) {
      if (alertKeyArr.current.includes(param.key)) {
        return () => {}
      }
      alertKeyArr.current.push(param.key)
    }

    const handleClose = () => {
      if (param?.key) {
        alertKeyArr.current = alertKeyArr.current.filter((k) => k !== param.key)
      }
    }

    return openDialog({
      slots: {
        button: DasButton as any,
        icon: Iconfont
      },
      actionButtonText: tt('OK'),
      enableCloseAction: true,
      onClose: handleClose,
      ...param
    })
  }

  const upgradeNoticeWithLange = useMemo(
    () => ({
      [LANGUAGE.en]: {
        service_upgrade_title: tt('Service upgrade'),
        service_upgrade_message: tt(
          'As we are upgrading the service, the service will be suspended during the upgrade.We will notify you once the upgrade has been completed. Please kindly follow our {twitter} for updates. Thank you for your support.',
          {
            twitter: (
              <span
                key={LANGUAGE.en}
                style={{ cursor: 'pointer', color: '#3E66B3' }}
                onClick={() => {
                  smartOpen('https://twitter.com/DIDbased')
                }}
              >
                Twitter
              </span>
            )
          }
        )
      },
      [LANGUAGE.zhCN]: {
        service_upgrade_title: tt('服务升级'),
        service_upgrade_message: tt(
          '升级期间，服务将无法使用。升级完成后，我们将第一时间通知，请关注 .bit {twitter} 获取最新信息。感谢你的支持。',
          {
            twitter: (
              <span
                key={LANGUAGE.zhCN}
                style={{ cursor: 'pointer', color: '#3E66B3' }}
                onClick={() => {
                  smartOpen('https://twitter.com/DIDbased')
                }}
              >
                Twitter
              </span>
            )
          }
        )
      }
    }),
    [tt]
  )

  const handleSystemUpgrade = () => {
    if (upgradeNoticeLoadingRef.current) {
      setTimeout(() => {
        handleSystemUpgrade()
      }, 1000)
      return
    }

    let info =
      upgradeNoticeWithLange[localeRef.current] ||
      upgradeNoticeWithLange[LANGUAGE.en]
    if (upgradeNoticeRef.current?.[localeRef.current]) {
      info = upgradeNoticeRef.current[localeRef.current]
    }

    alert({
      key: ALERT_KEY.SYSTEM_UPGRADE,
      title: info.service_upgrade_title,
      message: info.service_upgrade_message
    })
  }

  const services = new Services({
    isProdData: config.isProdData,
    baseURL: config.baseURL,
    reverseApiUrl: config.reverseApiUrl || '',
    didPointApiUrl: config.didPointApiUrl || '',
    subAccountApiUrl: config.subAccountApiUrl || '',
    onFulfilled: onFulfilled({ handleSystemUpgrade }),
    onRejected: onRejected(),
    headers: config.headers
  })

  useEffect(() => {
    const prevLang = window.localStorage.getItem(
      LOCAL_LANG_KEY
    ) as any as LANGUAGE
    const defaultLang = LanguageOptions.find((l) =>
      l.matcher.exec(navigator?.language)
    )
    setLocale(prevLang || defaultLang?.value || LANGUAGE.en)
  }, [])

  useEffect(() => {
    if (!wallet) return
    wallet.setLocale?.(locale)
  }, [locale, wallet])

  useEffect(() => {
    if (!locale) return
    const translationData =
      localesData?.find((l) => l.key === locale)?.data || {}
    setTranslation(translationData)
  }, [locale, localesData])

  useEffect(() => {
    if (walletIsInited.current) return
    walletIsInited.current = true
    const initWallet = async () => {
      const { Wallet, getWalletState, CustomChain, CustomWallet } =
        await import(
          // @ts-ignore
          'wallet-bridge'
        )

      if (!Wallet) return

      const walletConnectOptions = {
        projectId: config.walletconnectProjectId,
        metadata: {
          name: '.bit',
          description: 'Your Barrier-free Human DID.',
          url: 'https://d.id',
          icons: ['https://d.id/favicon.ico']
        }
      }

      const wagmiConfig = createConfig({
        chains: [ethereum, holesky, bsc, bscTestnet, polygon, polygonAmoy],
        transports: {
          [ethereum.id]: http(),
          [holesky.id]: http(),
          [bsc.id]: http(),
          [bscTestnet.id]: http(),
          [polygon.id]: http(),
          [polygonAmoy.id]: http()
        },
        connectors: [injected(), walletConnect(walletConnectOptions)]
      })

      const _wallet = new Wallet({
        isTestNet: !config.isProdData,
        loggedInSelectAddress: false,
        wagmiConfig,
        customChains: customChains
      })

      const getConnectedAccount = async () => {
        const { walletSnap } = getWalletState()

        let connectedAccountInfo: any = {
          protocol: walletSnap.protocol,
          address: walletSnap.address,
          chain: walletSnap.coinType && CoinTypeToChainMap[walletSnap.coinType],
          deviceData: walletSnap.deviceData
        }

        connectedAccountInfo = await calcAccount({
          connectedAccountInfo,
          isProdData: config.isProdData
        })

        setConnectedAccount(connectedAccountInfo)
      }

      await _wallet.initWallet({ involution: false })

      await getConnectedAccount()

      _wallet?.walletSDK.context?.addEventListener(
        'walletConnect',
        async () => {
          await getConnectedAccount()
        }
      )

      _wallet?.walletSDK.context?.addEventListener(
        'walletDisconnect',
        async () => {
          await getConnectedAccount()
        }
      )

      _wallet?.walletSDK.context?.addEventListener('walletChange', async () => {
        await getConnectedAccount()
        // window.location.reload();
      })
      setWallet(_wallet)
    }

    initWallet()
  }, [])

  const contextVal = useMemo<IContext>(() => {
    if (!translation) return {} as any

    return {
      tt,
      lang: LanguageOptions.find((l) => l.value === locale),
      setLang: (lang: ILanguageOption) => {
        setLocale(lang.value)
        window.localStorage.setItem(LOCAL_LANG_KEY, lang.value)
      },
      services,
      walletSdk: wallet,
      connectedAccount,
      toast: openToast,
      alert,
      isProd: config.isProdData,
      monitor,
      router: {
        ...router,
        push: async (
          path: string | null | undefined,
          query?: string | null | ParsedUrlQueryInput | undefined,
          options?: any
        ): Promise<boolean> => {
          return await router.push(
            { pathname: path, query },
            undefined,
            options
          )
        }
      },
      outSiteUrl,
      ckbNode: config?.ckbNode,
      typeId
    }
  }, [
    locale,
    translation,
    wallet,
    router,
    connectedAccount,
    outSiteUrl,
    config?.ckbNode
  ])

  return contextVal
}

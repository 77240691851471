import {
  CCCBalanceTokenId,
  CoinType,
  DASBalanceTokenId,
  DASDidPointTokenId,
  DOGE,
  USD
} from '@did/constants/chain'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { PaymentConfirmType } from '@did/das-app-types/module'
import DasBalanceSvg from '@did/das-assets/images/components/das-balance.svg'
import CccBalanceSvg from '@did/das-assets/images/components/ccc-balance.svg'
import UsdSvg from '@did/das-assets/images/components/usd.svg'
import DidPointSvg from '@did/das-assets/images/icon/did-point.svg'
import { isMobile, isTokenPocket, thousandSplit, cn } from '@did/tools'
import { IToken } from '@did/types'
import { LANGUAGE } from '@did/types/uikit'
import { CheckBox, DasButton, Iconfont, IconImage } from '@did/uikit'
import React, { useEffect, useMemo } from 'react'

const PaymentErrorTips = ({ selectedToken }: { selectedToken?: IToken }) => {
  const { tt } = useDasBalanceContext()

  const errorToken = useMemo(() => {
    if (selectedToken?.coin_type === CoinType.doge && !isTokenPocket()) {
      return DOGE.symbol
    }
    return null
  }, [selectedToken])

  if (!errorToken) return <></>

  return (
    <div className=" my-2 text-sm text-red-700 text-center font-semibold">
      {tt('The wallet environment does not support {token} payments', {
        token: `${errorToken}`
      })}
    </div>
  )
}

const HelpLink = ({ href, text }: any) => {
  return (
    <a
      className="flex items-center my-3 px-3 cursor-pointer text-neutral-400 text-sm font-normal hover:opacity-70"
      href={href}
      target="_blank"
    >
      <Iconfont name="info" size="14" />
      <span className="ml-1">{text}</span>
    </a>
  )
}

export const PaymentConfirm: PaymentConfirmType = ({
  tokenList,
  selectedToken,
  onTokenChange,
  onConnectLegacyWallet,
  fiatTermsLink,
  stripeServiceFee,
  paidTokenAmount,
  stripePaidAmount,
  handleConfirm,
  showAll,
  confirmRegisterLoading,
  className
}) => {
  const { tt, lang, outSiteUrl, connectedAccount, router } =
    useDasBalanceContext()
  const { isDobsMode, ckbAddress } = useCccContext()
  const [isAgreeFiatTerms, setIsAgreeFiatTerms] = React.useState(false)

  const payDisabled = useMemo(() => {
    if (!selectedToken) return true
    // Check if the selected token is supported
    return (
      (selectedToken.coin_type === CoinType.doge && !isTokenPocket()) ||
      (selectedToken.token_id === USD.tokenId && !isAgreeFiatTerms) ||
      (selectedToken.token_id === DASBalanceTokenId && !connectedAccount?.address) ||
      (selectedToken.token_id === DASDidPointTokenId && !connectedAccount?.address)
    )
  }, [selectedToken, isAgreeFiatTerms, ckbAddress])

  const ckbWalletOptions = () => {
    const options = tokenList.filter((option: IToken) => {
      return option.token_id === DASBalanceTokenId
    })

    if (options) {
      return options
    }
    return []
  }

  const cccWalletOptions = () => {
    const options = tokenList.filter((option: IToken) => {
      return option.token_id === CCCBalanceTokenId
    })

    if (
      options &&
      isDobsMode &&
      ckbAddress &&
      ['/my/upgradeable-list', '/data/[bitname]'].includes(router?.pathname)
    ) {
      return options
    }
    return []
  }

  const dpOptions = () => {
    const options = tokenList.filter((option: IToken) => {
      return option.token_id === DASDidPointTokenId
    })

    if (options) {
      return options
    }
    return []
  }

  const stripeOptions = () => {
    const options = tokenList.filter((option: IToken) => {
      return option.token_id === USD.tokenId
    })

    if (options && connectedAccount?.chain?.coinType === CoinType.ckb) {
      return options
    }
    return []
  }

  const paymentList = useMemo<
    Array<IToken & { svgLogo?: React.FC<any> }>
  >(() => {
    if (!tokenList) return []
    if (showAll) return tokenList

    let currentChain = connectedAccount?.chain?.coinType || CoinType.ckb

    const showOptions = (): IToken[] => {
      let res: IToken[] = []
      if (currentChain !== undefined && currentChain !== null) {
        res = tokenList
          .filter((option: IToken) => {
            return (
              option.coin_type === currentChain &&
              option.coin_type !== CoinType.ckb
            )
          })
          .map((option: IToken) => {
            option.symbol = option.symbol?.toUpperCase()
            return option
          })
      }
      return res
    }

    return [
      ...dpOptions(),
      ...stripeOptions(),
      ...showOptions(),
      ...ckbWalletOptions(),
      ...cccWalletOptions()
    ].map((t) => {
      if (t.token_id === DASBalanceTokenId) {
        return { ...t, svgLogo: DasBalanceSvg }
      }
      if (t.token_id === CCCBalanceTokenId) {
        return { ...t, svgLogo: CccBalanceSvg }
      }
      if (t.token_id === USD.tokenId) {
        return { ...t, svgLogo: UsdSvg, name: tt('by stripe') }
      }
      if (t.token_id === DASDidPointTokenId) {
        return { ...t, svgLogo: DidPointSvg, name: tt('Convenient & Fast') }
      }
      return t
    })
  }, [tokenList, showAll])

  useEffect(() => {
    if (!paymentList) return
    if (!selectedToken) {
      onTokenChange?.(paymentList[0])
      return
    }
  }, [paymentList, selectedToken])

  const handleConnectWallet = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    onConnectLegacyWallet?.()
  }

  return (
    <div className={cn('px-8 pb-8', className)}>
      <ul className="bg-neutral-100 rounded-xl border border-neutral-200 overflow-hidden">
        {paymentList?.map((t) => (
          <li
            key={t.name}
            className="px-4 py-3 flex items-center justify-between hover:bg-gray-200 cursor-pointer"
            onClick={() => {
              onTokenChange?.(t)
            }}
          >
            <span>
              <span className="flex items-center">
                {t.svgLogo && (
                  <t.svgLogo className="rounded-full mr-3 w-9 h-9" />
                )}
                {!t.svgLogo && (
                  <IconImage
                    alt={t.name}
                    url={t.logo}
                    size={36}
                    className=" mr-3"
                  />
                )}
                <span className="flex flex-col justify-center">
                  <span className="text-base font-medium leading-5 text-slate-900">
                    {t.symbol === 'Credits' ? 'DIDCredits' : t.symbol}
                  </span>
                  <span className="text-xs font-normal text-gray-500">
                    {t.name}
                  </span>
                </span>
              </span>
            </span>
            <span
              className={cn(
                'w-5 h-5 rounded-full flex items-center justify-center',
                {
                  'bg-emerald-400': selectedToken?.token_id === t.token_id,
                  'bg-white border-2 border-gray-400':
                    selectedToken?.token_id != t.token_id
                }
              )}
            >
              {selectedToken?.token_id === t.token_id && (
                <Iconfont name="check" color="#FFFFFF" size="16" />
              )}
              {selectedToken?.token_id != t.token_id && <span></span>}
            </span>
          </li>
        ))}
      </ul>
      {selectedToken?.token_id === USD.tokenId && (
        <>
          <CheckBox
            checked={isAgreeFiatTerms}
            onChange={setIsAgreeFiatTerms}
            className=" mt-4"
          >
            <span className=" text-sm font-medium flex items-center ">
              {tt('I have read and agreed to the')}
              <a
                className=" text-sky-700 hover:opacity-70 ml-1 cursor-pointer"
                href={fiatTermsLink}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation()
                  window.top?.open(fiatTermsLink)
                }}
              >
                {tt('Terms')}
              </a>
            </span>
          </CheckBox>
          <div className="my-6">
            <div className="flex justify-between items-center text-sm text-gray-500">
              <span>{tt('Stripe service fee')}</span>
              <span>{`$${thousandSplit(stripeServiceFee)}`}</span>
            </div>
            <div className="flex justify-between items-center text-xl font-bold mt-2">
              <span>{tt('Total')}</span>
              <span>{`$${thousandSplit(stripePaidAmount)}`}</span>
            </div>
          </div>
        </>
      )}
      {selectedToken?.token_id !== USD.tokenId && (
        <>
          {/* Display the DIDPoint guide link, if the user choosed DIDPoint as the payment method */}
          {selectedToken?.token_id === DASDidPointTokenId && (
            <>
              {!connectedAccount?.address && (
                <a
                  className="flex justify-between items-center px-3 my-3 bg-status-warn-bg-color text-error-font-color border-[#dcf6e2] border rounded-xl h-9 cursor-pointer"
                  onClick={handleConnectWallet}
                >
                  <span className="text-sm font-normal">
                    {tt('Connect your legacy .bit wallet first')}
                  </span>
                  <Iconfont name="arrow-right" color="#C4D0CD" size="16" />
                </a>
              )}
              <HelpLink
                text={tt('What is DIDPoint and how to use it?')}
                href={'https://community.d.id/c/knowledge-base-bit/didpoint-faq'}
              />
            </>
          )}

          {/* Display the deposit guide link, if the user choosed DAS Balance as the payment method */}
          {selectedToken?.token_id === DASBalanceTokenId && (
            <>
              {!connectedAccount?.address && (
                <a
                  className="flex justify-between items-center px-3 my-3 bg-status-warn-bg-color text-error-font-color border-[#dcf6e2] border rounded-xl h-9 cursor-pointer"
                  onClick={handleConnectWallet}
                >
                  <span className="text-sm font-normal">
                    {tt('Connect your legacy .bit wallet first')}
                  </span>
                  <Iconfont name="arrow-right" color="#C4D0CD" size="16" />
                </a>
              )}
              <a
                className="flex justify-between items-center px-3 my-3 bg-[#f2fff5] border-[#dcf6e2] border rounded-xl h-9 cursor-pointer"
                href={outSiteUrl?.dasBalanceUrl}
                target={isMobile() ? '_self' : '_blank'}
              >
                <span className=" text-sm font-normal">
                  {tt('Deposit CKB to DAS Balance')}
                </span>
                <Iconfont name="arrow-right" color="#C4D0CD" size="16" />
              </a>
              <HelpLink
                text={tt('How to register with CKB (DAS Balance)?')}
                href={
                  lang?.value === LANGUAGE.zhCN
                    ? 'https://talk.did.id/t/ckb-das-das-0-gas-ckb/284'
                    : 'https://talk.did.id/t/how-do-i-register-das-account-with-ckb-0-gas-no-ckb-wallet-needed/285'
                }
              />
            </>
          )}

          {/* Display the paid amount */}
          <div className="mt-8 mb-6 text-slate-900 text-[32px] font-semibold font-['Barlow-Medium'] flex justify-center">
            <span>{thousandSplit(paidTokenAmount)}</span>
            <span className=" ml-1 text-neutral-400">
              {selectedToken?.symbol}
            </span>
          </div>
          {/* Display the payment error tips, if the user choosed a payment method that is not supported */}
          <PaymentErrorTips selectedToken={selectedToken} />
        </>
      )}

      <DasButton
        disabled={payDisabled}
        loading={confirmRegisterLoading}
        isLoadingGradient={false}
        block
        black
        large
        onClick={() => {
          if (payDisabled) return
          handleConfirm?.()
        }}
      >
        {tt('Pay')}
      </DasButton>
    </div>
  )
}
